import { format } from 'v-money3';

function formatCurrency(value:any) {
  const config:any = {
    decimal: ".",
    thousands: ",",
    prefix: "$",
    suffix: "",
    precision: 2,
    masked: false /* doesn't work with directive */,
  };
  
  return format(value, config);
}

export {formatCurrency};