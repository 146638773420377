
import QuoterVue from './views/QuotesFormView/Quoter.vue';

<template>
  <div id="app">
     <transition    enter-active-class="animated bounceInLeft"
            leave-active-class="animated bounceOutLeft">
          <keep-alive include="Quoter">
            <router-view/>
          </keep-alive>
     </transition>
  </div>
</template>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
