import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import { isAuth,is_valid_Jwt } from "@/helpers/TokenAuth";
import Swal from 'sweetalert2';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: false },
    component: HomeView
  },
  {
    path: '/productos-vida-simple',
    name: 'VidaSimple',
    component:()=> import("@/views/Products/HomePageVidaSimpleView.vue"),
    children:[
      {
        path:"",
        name:"vidasimple-producto",
        meta: { requiresAuth: true },
        component:()=> import("@/views/Products/VidaSimpleView.vue"),
      },
      {
        path:"cotizacion",
        name:"HomeQuoter",
        meta: { requiresAuth: true },
        component:()=> import("@/views/QuotesFormView/HomeQuoter.vue"),
        children:[
          {
            path:"",
            name:"HomePageQuoter",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/HomePageQuoter.vue"),
          },
          {
            path:"preguntas",
            name:"Quoter",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/Quoter.vue")
          },
          {
            path:"resumen-de-cotizacion",
            name:"QuoteSummary",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/QuoteSummary.vue")
          },
          {
            path:"hacer-una-cotizacion",
            name:"Quotation",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/Quotation.vue")
          },
          {
            path:"preguntas-de-contratacion",
            name:"HiringQuestionsView",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/HiringQuestions.vue")
          },
          {
            path:"preguntas-de-contratacion-carga-documentos",
            name:"UploadDocumentView",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/UploadDocument.vue")
          },
          {
            path:"resumen-de-contratacion",
            name:"QuotationResume",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/QuotationResume.vue")
          },
          {
            path:"enviar-documento-para-firma",
            name:"enviar-documento-para-firma",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/DocumentSentForSignatureView.vue")
          },
          {
            path:"seleccion-firmar-documento",
            name:"seleccion-firmar-documento",
            meta: { requiresAuth: true },
            component:()=> import("@/views/QuotesFormView/SelectWhereToSignDocumentView.vue")
          },
          {
            path:"compra-exitosa/:id",
            name:"FinalProcess",
            meta: { requiresAuth: false },
            component:()=> import("@/views/QuotesFormView/FinalProcess.vue")
          },
          {
            path:"domiciliar-pago",
            name:"DomiciliarPago",
            meta: { requiresAuth: false },
            component:()=> import("@/views/QuotesFormView/PagoDomiciliacion.vue")
          },
        ]
      },
      {
        path:"realizar-pago",
        name:"RedirectPaymentLink",
        meta: { requiresAuth: false },
        component:()=> import("@/views/RedirectPaymentLink.vue")
      },
      
    ]
  },
  {
    path:"/login",
    name:"Login",
    meta: { requiresAuth: false },
    component:()=> import("@/views/Login.vue")
  },
  {
    path:"/:role/dashboard",
    name:"DashboardView",
    meta: { requiresAuth: true },
    component:()=> import("@/views/Administrative/DashboardView.vue")
  },
  {
    path:"/recuperar-cuenta",
    name:"RecoverAccountView",
    meta: { requiresAuth: false },
    component:()=> import("@/views/RecoverAccountView.vue")
  },
  {
    path:"/registrar-nueva-cuenta",
    name:"RegisterNewAccountView",
    meta: { requiresAuth: true },
    component:()=> import("@/views/RegisterNewAccountView.vue")
  },
  {
    path:"/restore_password/:keypassword",
    name:"RestorePassword",
    meta: { requiresAuth: false },
    component:()=> import("@/views/RestorePasswordView.vue")
  },
  {
    path:"/actualizar-password",
    name:"ChangePasswordAccount",
    meta: { requiresAuth: false },
    component:()=> import("@/views/ChangePasswordAccountView.vue")
  },
  {
    path:"/:code",
    name:"ActivateAccount",
    meta: { requiresAuth: false },
    component:()=> import("@/views/ActivateAccountView.vue")
  } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const _meta = to.meta;
  
  if(_meta.requiresAuth){
    const aunth = isAuth();
    if(!aunth){
      Swal.fire({
        icon:"warning",
        title:"Precaución.",
        text:"Para ver esta pantalla tienes que iniciar session.",
        allowOutsideClick:false
      });
      next({ path:"/login"});
    }
    
    const is_valid_token = is_valid_Jwt();

    if(!is_valid_token){
      Swal.fire({
          icon:'error',
          title:"Cuidado!",
          html: "Su sesión ha caducado.",
          allowOutsideClick:false
      });
      next({ path:"/login"});
    }
  }
  next();
});

export default router
