
import {defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router';


export default defineComponent({
    name:"VueLoading",
    emits: ['goBack'],
    props: ['checkIcon'],
    setup(props:any, ctx:any){
        // console.log(props)
        const router = useRouter();
        const HamburgerMenu= ref(false);
        const linksRouter=ref([
            {
                name:"Home",
                link:"/",
                id:"home",
                isAunth:false
            },
            {
                name:"Vida Simple",
                link:"/productos-vida-simple",
                id:"productos-vida-simple",
                isAunth:false

            },
            {
                name:"Cambiar contraseña",
                link:"/actualizar-password",
                id:"actualizar-password",
                isAunth:true

            },
            {
                name:"Registrar nueva cuenta",
                link:"/registrar-nueva-cuenta",
                id:"registrar-nueva-cuenta",
                isAunth:true

            },
            {
                name:"Iniciar Sesión",
                link:"/login",
                id:"login",
                isAunth:false
            },
            {
                name:"Cerrar Sesión",
                link:"cerrar sesion",
                id:"Cerrar Sesión",
                isAunth:false
            }
        ]);
        function router_go_back(){
            ctx.emit('goBack')
        }

        function logOut(){
            localStorage.clear();
            router.push({ path:"/login" });
        }

        function hideMenuItemsLoginPage(){
            console.log(linksRouter.value)
            if(router.currentRoute.value.name === 'Login'){ 
                linksRouter.value = linksRouter.value.filter(link => link.name === 'Home')
            }
        }

        function openHamburger(){
            hideMenuItemsLoginPage()
            HamburgerMenu.value = !HamburgerMenu.value;
        }

        function show_link_in_menu(linkObjet:any){
            //console.log("linkObjet; ", linkObjet);
            const localstorageItem = localStorage.getItem("LoginVSCR");
            
            if( linkObjet.link=="/login" ){
                if(localstorageItem!=null){
                    return false;
                }
                return true;
            }

            if(linkObjet.link=="cerrar sesion"){
                if(localstorageItem != null){
                    return true;
                }
                return false;
            }

            if(!linkObjet.isAunth){
                return true;
            }

            if(localstorageItem==null){
                return false;
            }

            return true;
        }

        
        // onMounted(() => {
        //     console.log("Mounted", props)
        // })

        function navigate_link(link:string){
            
            if(link == "cerrar sesion"){
                logOut();
                return;
            }

            router.push({path:link});
            
        } 
        
        return{
            router_go_back,
            logOut,
            openHamburger,
            show_link_in_menu,
            HamburgerMenu,
            linksRouter,
            navigate_link
        }
    }
})


