function isAuth() {
    const _sessionStorage = localStorage.getItem("LoginVSCR");
    if(_sessionStorage == null){
        return false;
    }

    return true;
}


function is_valid_Jwt(){
    const _sessionStorage = localStorage.getItem("LoginVSCR");
    if(_sessionStorage != null){
        const _JWT = decode_token(_sessionStorage);
        const d = new Date(0);
        d.setUTCSeconds(_JWT.exp);
        return (d.getTime())<(new Date().getTime()) ? false : true;
    }
    return false;
}

function decode_token (token:string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}



export {isAuth,is_valid_Jwt};