
import { defineComponent } from "@vue/runtime-core";
import NavBard from "@/components/NavBard.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HomeView",
  components: {
    NavBard,
  },
  setup(){
    
    const router = useRouter();
    const catalogs=[{name:"Nuestra Empresa",url:"/"},{name:"Nosotros",url:"/"},{name:"Vida Simple",url:"/productos-vida-simple"}];
    const router_go_back = () => router.go(-1);

    return { catalogs, router_go_back}
  }
});
