import { createApp } from 'vue';
import '@/assets/css/app.css';
import '@/assets/tailwind.css'
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'animate.css'
import money from 'v-money3'
import Maska from 'maska';

import { Money3Component } from 'v-money3'

import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';


import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker';
import InputReactiveForm from "input-reactive-for-vue";
import ComponentsPersonalData from 'components-personal-data';
import LoginComponent from 'basic-forms-vue/src/Login';
import RestorePassword from 'basic-forms-vue/src/RestorePassword';
import RegisterNewAccount from 'basic-forms-vue/src/RegisterNewAccount';
import ChangePassword from 'basic-forms-vue/src/ChangePassword';
import PagoDomiciliacion from 'basic-forms-vue/src/PagoDomiciliacion';

// createApp(App).use(store).use(router).mount('#app')
import { formatCurrency } from '@/helpers/filters';
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app= createApp(App)
.use(pinia)
.use(VueAxios, axios)
.use(router)
.use(store)
.use(Maska)
.use(VueSweetalert2)
.use(money)

app.component("DatePicker", Vue3PersianDatetimePicker);
app.component("InputReactiveFormSimple",InputReactiveForm);
app.component("ComponentsPersonalData",ComponentsPersonalData);
app.component("MoneyVue3", Money3Component)
app.component("LoginComponent",LoginComponent);
app.component("RestorePassword",RestorePassword);
app.component("RegisterNewAccountComponent",RegisterNewAccount);
app.component("ChangePasswordComponent",ChangePassword);
app.component("PagoDomiciliacion",PagoDomiciliacion);


app.mount('#app');

app.config.globalProperties.$filters = { formatCurrency }